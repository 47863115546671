define("discourse/plugins/hosted-site/discourse/initializers/anon-init", ["exports", "@ember/runloop", "jquery", "discourse-common/lib/get-owner", "discourse-common/utils/decorators"], function (_exports, _runloop, _jquery, _getOwner, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "apply-anon-init",
    initialize() {
      const GlobalNoticeComponent = (0, _getOwner.getRegister)(this).lookupFactory("component:global-notice");
      if (GlobalNoticeComponent) {
        GlobalNoticeComponent.class.reopen(dt7948.p({
          _hideOtherAlerts() {
            (0, _runloop.schedule)("afterRender", () => {
              if ((0, _jquery.default)(".alert .trial-expired-message").length > 0 || (0, _jquery.default)(".alert .discourse-hosting-readonly").length > 0) {
                (0, _jquery.default)(".alert-read-only").hide();
                (0, _jquery.default)(".alert-too-few-topics").hide();
              }
            });
          }
        }, [["method", "_hideOtherAlerts", [(0, _decorators.on)("didInsertElement")]]]));
      }
    }
  };
});
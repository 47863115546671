define("discourse/plugins/hosted-site/discourse/initializers/extend-invited", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "hosted-site";
  function initialize(api) {
    api.modifyClass("controller:user-invited-show", dt7948.p({
      pluginId: PLUGIN_ID,
      canBulkInvite(admin, remainingUsers) {
        return admin && (remainingUsers === undefined || Number(remainingUsers) > 1);
      }
    }, [["method", "canBulkInvite", [(0, _decorators.default)("currentUser.admin", "model.remaining_users")]]]));
  }
  var _default = _exports.default = {
    name: "extend-invited",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.40", api => initialize(api, container));
    }
  };
});
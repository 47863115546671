define("discourse/plugins/hosted-site/discourse/templates/connectors/wizard-checkbox/basic-plan-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.disabled}}
    <DTooltip @triggers="hover">
      <:trigger>
        {{yield}}
      </:trigger>
      <:content>
        <div class="wizard-checkbox-tooltip">
          <h3>{{i18n "basic_plan.tooltip.headline"}}</h3>
          <p>{{i18n "basic_plan.tooltip.description"}}</p>
        </div>
      </:content>
    </DTooltip>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "zI3AAdii",
    "block": "[[[41,[30,0,[\"disabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@triggers\"],[\"hover\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[10,0],[14,0,\"wizard-checkbox-tooltip\"],[12],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[35,3],[\"basic_plan.tooltip.headline\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,3],[\"basic_plan.tooltip.description\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"d-tooltip\",\"yield\",\"i18n\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/templates/connectors/wizard-checkbox/basic-plan-tooltip.hbs",
    "isStrictMode": false
  });
});